import React from "react";
import { Box, Img, Text } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import imgWhoWeAre from "../../../images/who-are-we.jpg";
import animation from "./_animation";

function WhoWeAre() {
  const { t, language } = useTranslation();

  return (
    <Box paddingTop="4xl">
      <ContentTitle
        titleBold={t("page@about-us@who-we-are@title-bold")}
        titleLight={t("page@about-us@who-we-are@title-light")}
        order="inline"
        titleAlign="center"
        spaces="0"
        keepOrder={true}
      >
        <Box paddingTop="4xl">
          <ViewportAnimation {...animation.image}>
            <Img draggable={false} src={imgWhoWeAre} width="100%" />
          </ViewportAnimation>
        </Box>

        <PageContainer>
          <Box paddingTop="2.8xl">
            <ViewportAnimation {...animation.text}>
              <Text
                fontSize={{ base: "2sm", md: "md" }}
                maxWidth="viewAboutWhoWeAreW"
                margin="0 auto"
                paddingRight="lg"
              >
                {t("page@about-us@who-we-are@line-1")}
                <br />
                <br />
                {t("page@about-us@who-we-are@line-2")}
              </Text>
            </ViewportAnimation>
          </Box>
        </PageContainer>
      </ContentTitle>
    </Box>
  );
}

export default WhoWeAre;
