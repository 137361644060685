const cardImageAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "5rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.65,
    delay: 0.8,
  },
};

const cardGradientAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "2.3rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.75,
    delay: 0.53,
  },
};

const textAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    transform: "scale(0)",
  },
  animate: {
    opacity: 1,
    transform: "scale(1)",
  },
  transition: {
    duration: 0.67,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cardImage: cardImageAnimation,
  cardGradient: cardGradientAnimation,
  text: textAnimation,
};
