const imageAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "1.4rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.75,
  },
};

const textAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "0.4rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.95,
    delay: 0.75,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  image: imageAnimation,
  text: textAnimation,
};
