import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";

import Hero from "../views/About/Hero";
import WhoWeAre from "../views/About/WhoWeAre";
import Experience from "../views/About/Experience";
// import OurTeam from "../views/common/OurTeam";

function About() {
  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <WhoWeAre />
        <Experience />
        {/* <OurTeam /> */}
      </AnimatedContent>
    </Box>
  );
}

export default About;
