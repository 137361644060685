import React from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import PageParallax from "../../../components/PageParallax";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import CardContainer from "../../../components/CardContainer";
import imgExperience from "../../../images/experience.png";
import imgExperienceBg from "../../../images/about-experience-cover.png";
import animation from "./_animation";

function Experience() {
  const { t, language } = useTranslation();

  return (
    <Box>
      <Box
        position="relative"
        paddingTop="4.2xl"
        minHeight={{ base: "", md: "viewAboutExperience" }}
      >
        <Box position="relative" zIndex="2">
          <PageContainer>
            <Box position="relative" minHeight="viewAboutExperienceCardH">
              <Box
                position={{ base: "", lg: "absolute" }}
                left={{ base: "", lg: "0" }}
                margin={{ base: "0 auto", lg: "0" }}
                width={{
                  base: "viewAboutExperienceCardWSmallLeft",
                  lg: "viewAboutExperienceCardWLeft",
                }}
                height={{
                  base: "",
                  lg: "viewAboutExperienceCardH",
                }}
              >
                <ViewportAnimation {...animation.cardImage}>
                  <PageParallax y={["-10%", "30%"]}>
                    <CardContainer
                      spacing={false}
                      boxShadow="0 12px 44px 0 rgba(0,0,0,0.05)"
                    >
                      <Box
                        width={{
                          base: "viewAboutExperienceCardWSmallLeft",
                          lg: "viewAboutExperienceCardWLeft",
                        }}
                        height={{
                          base: "viewAboutExperienceCardHSmall",
                          lg: "viewAboutExperienceCardH",
                        }}
                        // background="blue.600"
                        backgroundImage={imgExperience}
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                      />
                    </CardContainer>
                  </PageParallax>
                </ViewportAnimation>
              </Box>

              <Box
                position={{ base: "", lg: "absolute" }}
                right={{ base: "", lg: "0" }}
                width={{ base: "", lg: "viewAboutExperienceCardWRight" }}
                height={{ base: "", lg: "viewAboutExperienceCardH" }}
                paddingTop={{
                  base: "viewAboutExperienceCardPSm",
                  xl: "viewAboutExperienceCardP",
                }}
              >
                <ViewportAnimation {...animation.text}>
                  <ContentTitle
                    title={t("page@about-us@experience@title")}
                    spaces="6"
                  >
                    {/* {t("page@about-us@experience@line-1")}
                    <br />
                    <br /> */}

                    {t("page@about-us@experience@line-2")}
                    <br />
                    <br />
                    {t("page@about-us@experience@line-3")}
                  </ContentTitle>
                </ViewportAnimation>
              </Box>
            </Box>
          </PageContainer>
        </Box>

        <ViewportAnimation {...animation.cardGradient}>
          <Box
            display={{ base: "none", lg: "block" }}
            position={{ base: "absolute", lg: "relative" }}
            zIndex="1"
            height="viewAboutExperienceGradientH"
            marginTop={{
              base: "",
              lg: "-viewAboutExperienceGradientTop",
            }}
            backgroundImage={imgExperienceBg}
            backgroundRepeat="no-repeat"
            backgroundSize={{ base: "", sm: "cover", lg: "contain" }}
            top={{ base: "0", lg: "unset" }}
            left="0"
            right="0"
            bottom="0"
          />
        </ViewportAnimation>
      </Box>

      <Box height="viewAboutExpressionB" />
    </Box>
  );
}

export default Experience;
